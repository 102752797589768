export default function(el) {
  let options = {
    maxNumberWidth: 10,
    axisLabelFontSize: 13,
    axes: {
      x: {pixelsPerLabel: 50},
      y: {pixelsPerLabel: 20}
    }
  };

  if (el.dataset.units)
    options['labels' + el.dataset.units] = true;

  new window.Dygraph(el, el.dataset.url, options);
}
