export default function(el) {
  const $form = $(el);
  const $referrers = $form.find('.api_key_referrers')
  const $ips = $form.find('.api_key_ips');

  $form.change(() => {
    $referrers.hide();
    $ips.hide();

    const val = $form.find('input[name="api_key[restriction]"]:checked').val()

    switch (val) {
      case 'referrers':
        $referrers.show();
        break;

      case 'ips':
        $ips.show();
        break;
    }
  });

  $form.trigger('change');
}
