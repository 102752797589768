export default function(el) {
  const $form = $(el);
  const $q = $form.find('textarea[name=q]');
  const $modal = $form.find('#demo-modal');
  const $submit = $form.find('input[type=submit]');
  const $resultEl = $form.find('.js-demo-result');

  function disableSubmit() {
    $submit.attr('disabled', 1);
    $submit.data('originalValue', $submit.val());
    $submit.val('Detecting...')
  }

  function enableSubmit() {
    $submit.val($submit.data('originalValue'));
    $submit.removeData('originalValue');
    $submit.removeAttr('disabled');
  }

  function showResult(result) {
    $resultEl.html(result);
    $modal.modal('show');
  }

  $form.submit((e) => {
    disableSubmit();

    fetch($form.attr('action'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        q: $q.val(),
      })
    }).then(async response => {
      showResult(await response.text());
      enableSubmit()
    }).catch(() => {
      enableSubmit()
    });

    e.preventDefault();
  });
}
