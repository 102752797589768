"use strict";

function validOrNull(str, maxLength) {
  if (!str) return;

  return str.length > maxLength ? null : str;
}

export default function(el) {
  const form = $($(el).data('form'));
  const token = $(el).data('token');
  const button = form.find('[type=button]');
  const amount = $(el).data('amount');
  const currency = $(el).data('currency');
  const email = validOrNull($(el).data('email'), 255);
  const accountCreateDate = String($(el).data('account-create-date') || '');
  const billingAddress = $(el).data('billing-address');
  const threeDSecure = !!amount; // do not use 3DS in payment method configuration page

  if (billingAddress) {
    billingAddress.streetAddress = validOrNull(billingAddress.streetAddress, 50);
    billingAddress.postalCode = validOrNull(billingAddress.postalCode, 10);
    billingAddress.locality = validOrNull(billingAddress.locality, 255);
  }

  const threeDSecureParameters = {
    amount,
    email,
    billingAddress,
    requestedExemptionType: 'transaction_risk_analysis',
    additionalInformation: {
      accountCreateDate,
      shippingMethod: '05', // Electronic Delivery
      deliveryTimeframe: '01', // Electronic delivery
      productCode: 'SVC', // Services
      addressMatch: 'Y', // Billing and shipping addresses match
    },
  };

  const options = {
    authorization: token,
    container: el,
    threeDSecure,
    dataCollector: true, // collect device data
    card: {
      cardholderName: true
    },
    googlePay: {
      googlePayVersion: 2,
      merchantInfo: {
        merchantId: 'BCR2DN6TT7MPTZTZ',
        merchantName: 'detectlanguage.com',
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: amount,
        currencyCode: currency
      },
      allowedPaymentMethods: [{
        type: 'CARD',
        parameters: {
          // We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
          billingAddressRequired: true,
          billingAddressParameters: {
            format: 'FULL'
          }
        }
      }]
    },
    paypal: {
      flow: 'vault',
      billingAgreementDescription: 'detectlanguage.com API',
      buttonStyle: {
        size: 'medium',
        shape: 'rect'
      }
    },
  }

  // payments settings page
  if (!amount) {
    options.googlePay = null;
  }

  window.braintree.dropin.create(options, function (err, instance) {
    if (err) {
      console.error(err.toString());
      return;
    }

    if (instance.isPaymentMethodRequestable()) {
      button.prop('disabled', false);
    }

    instance.on('paymentMethodRequestable', function () {
      button.prop('disabled', false);
    });

    instance.on('noPaymentMethodRequestable', function () {
      button.prop('disabled', true);
    });

    button.click(function () {
      const oldValue = button.val();
      button.prop('disabled', true).val('Processing...');

      instance.requestPaymentMethod({
        threeDSecure: threeDSecureParameters
      }, function (err, payload) {
        if (err) {
          console.error(err);
          button.prop('disabled', false).val(oldValue);
          alert(err.message);
          return;
        }

        form.find('#payment_method_nonce').val(payload.nonce);
        form.find('#device_data').val(payload.deviceData);
        form.submit();
      });
    });
  });
}


