export default function(el) {
  const $el = $(el);
  const $form = $(el.form);
  const $companyLabel = $form.find('.user_company label')
  const $registrationCode = $form.find('.user_registration_code');
  const $vatCode = $form.find('.user_vat_code');
  const $requiredAbbr = $companyLabel.find('abbr')

  $el.change(() => {
    if ($el.val() == 'business') {
      $companyLabel.text('Organization name')
      $companyLabel.prepend($requiredAbbr, ' ');
      $registrationCode.show();
      $vatCode.show();
    } else {
      $companyLabel.text('Name')
      $companyLabel.prepend($requiredAbbr, ' ');
      $registrationCode.hide();
      $vatCode.hide();
    }
  });

  $el.trigger('change');
}
