require('@rails/ujs').start();

import 'bootstrap/dist/js/bootstrap';
import Clipboard from 'clipboard';
import UserBillingDetails from 'elements/UserBillingDetails';
import DemoForm from 'elements/DemoForm';
import APIKeyForm from 'elements/APIKeyForm';
import BraintreeDropIn from 'braintree/DropIn';
import UsageGraph from 'graphs/Usage';
import '_prism';

$(function() {
  new Clipboard('.js-clipboard');

  $('[data-toggle="tooltip"]').tooltip();

  $('.js-braintree-dropin').each(function() {
    BraintreeDropIn(this)
  });

  $('.js-usage-graph').each(function() {
    UsageGraph(this)
  });

  $('#user_account_type').each(function() {
    UserBillingDetails(this)
  });

  $('form.edit_api_key').each(function() {
    APIKeyForm(this)
  });

  $('.js-doc-examples').each(function() {
    $(this).find('.nav-item:first a').tab('show');
  });

  $('.js-demo').each(function() {
    DemoForm(this);
  })
});
